.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    display: none; /*display flex whenever needed to show*/
    -ms-display: none;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px 0;
    width: 100vw;
    background: rgba(0,0,0,0.5);
    z-index: 499;
    overflow: hidden;
    opacity: 0;
    transition: 1000ms ease;

}

.dialog-background.image-dialog {
    z-index: 600;
    flex-direction: column;
    background: rgba(0,0,0,0.7);
    align-items: center;
    justify-content: center;
}

.dialog-background.image-dialog .image-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 50vw;
    height: auto;
    padding: 10px;
    box-shadow: 5px 12px 30px black;
}

.dialog-container {
    /* display: block;
    max-width: 90%;
    max-height: 95%;
    width: 800px;
*/
    display: flex;
    flex-direction: column;
    width: 700px;
    max-width: 800px;
    margin: 1.75rem auto;
    position: relative;
    background: linear-gradient(45deg, white, whitesmoke);
    opacity: 1;
    padding: 20px 20px 30px 20px;
    font-family: Montserrat;
    pointer-events: auto;
    border-radius: .3rem;
    outline: 0;
    box-shadow: 0px 12px 30px rgba(0,0,0,0.7);
}


/* IMAGE CONTAINER */
/* Style the Image Used to Trigger the Modal */
/* The Modal (background) */
#image-viewer {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
}
#full-image {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 700px;
}
#full-image {
    animation-name: zoom;
    animation-duration: 0.6s;
}
@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}
#image-viewer .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
#image-viewer .close:hover,
#image-viewer .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 700px){
    #full-image {
        width: 100%;
    }
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: auto;
    min-height: 90vh;
}

.embed-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    min-height: auto;

}

/* IMAGE ENDS HERE */


.dialog-container .dialog-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dialog-header > .create,
.dialog-header > .nully,
.dialog-header > .edit {
    position: relative;
    display: inline-block;
    font-weight: bold;
    font-size: 1.5em;
    color: var(--faded-main-green);
}

.dialog-header > .create::before,
.dialog-header > .edit::before {
    position: absolute;
    content: '\efc2';
    left: 0;
    top: -2px;
    font-family: 'Icofont';
    font-size: .5em;

}

.dialog-header > .edit::before {
    content: '\ec55';
}

.dialog-header > .nully::before {
    content: '';
}

.dialog-container .dialog-body {
    position: relative;
    display: block;

}

.dialog-container .dialog-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

}

.dialog-background.fade {
    display: flex;
    -ms-display: flex;
    opacity: 1;
    transition: opacity .15s linear;
}
