.loader {
    position: fixed;
    top: 0;
    left: 0;
    display: flex; /*display flex whenever needed to show*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: #444;
    z-index: 502;
    overflow: hidden;
    opacity: 0.7;
}

.loader .wavy {
    position: relative;
    -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,0.3));
}

.loader .wavy span {
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-family: Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3em;
    font-weight: 500;
    margin-right: 10px;
    animation: animate 1.5s ease-in-out infinite;
    animation-delay: calc(0.1s * var(--i));
    overflow: hidden;
}

@keyframes animate {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-20px);
    }
    40%, 100% {
        transform: translateY(0px);
    }
}

.loader .roller {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    background: #fff;
    margin-right: 20px;
    border-radius: 2px;
    animation: spin-block 1.2s ease-in-out infinite;
}

@keyframes spin-block {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

#spinner {
    display: block;
    font-size: 26px;
    color: var(--main-green);
    position: absolute;
    margin-top: 20px;
    transform-origin: center;
    animation: spin-block 750ms linear infinite;

}

#spinner-2 {
    display: inline-block;
    font-size: 26px;
    color: var(--main-green);
    line-height: 20px;
    position: absolute;
    right: 10px;
    bottom: 16px;
    transform-origin: center;
    animation: spin-block 1200ms linear infinite;

}
