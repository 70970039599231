.lock-screen {
    position: fixed;
    top: 0;
    left: 0;
    display: none; /*display flex whenever needed to show*/
    -ms-display: none;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    width: 100vw;
    background: rgba(0,0,0,0.66);
    z-index: 501;
    overflow: hidden;
    height: 100%;
    opacity: 0;
    transition: 1000ms ease;

}

.lock-container {
    display: block;
    position: relative;
    width: 450px;
    height: 215px;
    background: linear-gradient(135deg,whitesmoke,gainsboro);
    opacity: 1;
    padding: 20px 20px 30px 20px;
    font-family: 'Montserrat';
    box-shadow: 5px 15px 35px rgba(0,0,0,0.5);

}

.lock-container.special {
    height: 240px;
}

.lock-container .dialog-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

}

.lock-container .dialog-header h3 {
    position: relative;
    display: inline-block;
    font-weight: bold;
    font-size: 1.5em;
    color: var(--faded-main-green);
}


.lock-container .dialog-body {
    position: relative;
    display: block;

}

.lock-container .dialog-body p {
    display: block;
    font-size: 13px;
    margin-bottom: 7px;
    color: var(--faded-text);
}

.lock-container .dialog-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;


}

.lock-container .dialog-body input[type=password]{
    position: relative;
    padding-left: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.lock-container .dialog-body .input-group > span {
    position: absolute;
    z-index: 1;
    left: 15px;
    transform: translateY(8px);
    font-size: 18px;
    color: var(--faded-text);

}

.lock-screen.fade {
    display: flex;
    -ms-display: flex;
    opacity: 1;

}