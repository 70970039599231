body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.url-container {
    display: flex;
    align-items:center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(45deg, #d3d3d8, #74a874);

}

.url-container .body-content {
    display: block;
    position: relative;
    min-width: 500px;
    box-shadow: 3px 6px 12px rgba(0,0,0,0.25);
    background: white;
    padding: 40px;
    height: auto;
    border-radius: 7px;
}

@media screen and (max-width: 767px){
    .url-container .body-content {
        min-width: 92%;
        width: 92%;
        padding: 30px 20px;
    }
}

tbody > tr > td:first-child {
    font-weight: bold;
}
