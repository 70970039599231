@font-face {
    font-family: 'Montserrat';
    src: url(./../font/poppins.ttf);
}

@font-face {
    font-family: 'M-Light';
    src: url(./../font/Montserrat/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'M-Bold';
    src: url(./../font/Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: 'Poppins-Light';
    src: url(./../font/Poppins/Poppins-ExtraLight.ttf);
}

:root {
    /* --main-green: #245936; --faded-main-green: #2d7445;*/
    --main-green: rgba(36, 89, 54, 0.9);
    --lighter-main-green: rgba(36, 89, 54, 0.8);
    --transparent-green: rgba(36, 89, 54, 0.295);
    --faded-main-green: rgba(36, 89, 54);
    --med-green: rgb(57, 107, 10);
    --bluish: rgb(81, 168, 177);
    --lime-green: #5bc200;
    --reddish: rgba(271, 36, 21, 0.6);
    --golden-rod: rgb(156, 127, 24);
    --faint-golden-rod: rgba(156, 127, 24, 0.6);
    --lighter-gold: rgb(209, 135, 0);
    --faded-text: #888;
    --lighter-text: #bbb;
    --normal-text: #222;
    --medium-text: #666;
    --danger: #dc3545;
    --success: #07741f;
    --warning: #ffc107;
    --info: #007bff;
    --light-red: #e23b11;
    --success-bg: rgba(159, 218, 159, 0.6);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
}

body {
    background: rgb(240, 239, 239);
    font-size: 12px;
}