@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

.access-container {
    display: block;
    margin: 50px;
    margin-top: 60px;
    font-family: 'Open Sans', sans-serif;
}

.access-container .access-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 30px;

}

.access-container .access-header h2 {
    font-family: 'Montserrat';
    font-size: 46px;
    color: var(--main-green);
    font-weight: 700;
    display: block;
    position: relative;
    margin: 15px 0 0;

}

.access-container .access-header h4 {
    font-family: 'Open Sans', sans-serif;
    display: block;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0.3px;
    color: var(--faded-text);
}

.access-container .access-grid {
    display: grid;
    position: relative;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 30px 0;
}

@media screen and (max-width: 767px) {
    .access-container .access-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 545px) {
    .access-container .access-grid {
        grid-template-columns: 1fr;
    }
}

.access-container .access-grid .access-grid-item {
    display: block;
    position: relative;
    background: #fff;
    border: 4px solid var(--lighter-text);
    border-radius: 8px;
    width: 267px;
    height: 300px;
    padding: 30px;
    box-shadow: 3px 8px 18px rgba(0,0,0,20%);
    cursor: pointer;
    transition: 1s ease;

}

.access-container .access-grid .access-grid-item:hover {
    transform: translateY(-10px);
}

.access-grid-item h2 {
    display: block;
    position: absolute;
    font-size: 42px;
    color: var(--golden-rod);
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    right: 30px;
}

.access-grid-item img {
    display: inline-block;
    width: 75%;
    margin-top: 20px;
}

.grid-item-body {
    display: block;
    position: absolute;
    top: 60%;
    width: 100%;
}
.access-grid-item h3 {
    display: block;
    position: relative;
    font-weight: 600;
    font-size: 20px;
    color: var(--bluish);
}

.access-grid-item p {
    position: relative;
    font-weight: 300;
    color: var(--faded-text);
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
}

.access-grid-item a {
    display: block;
    position: relative;
    font-weight: 500;
    text-decoration: none;
    background: var(--golden-rod);
    color: #fff;
    padding: 4px 15px;
    border-radius: 4px;
    font-size: 13px;
    text-align: center;
    width: calc(100% - 60px);
}

.access-grid-item a:hover {
    background: var(--bluish);
    box-shadow: 0 0 0 5px rgba(0,0,0,20%);
}

.access-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    transition: 0.3s ease-in;
}

.access-body > img {
    display: block;
    position: relative;
    width: 50%;
    transition: 0.3s ease-in;
}

.access-body > h3 {
    display: block;
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 26px;
    color: #888;
    transition: 0.3s ease-in;
    font-weight: 400;
    margin-top: 20px;
}
