body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .MuiOutlinedInput-input  {
  padding: 10.5px 14px !important;
} */

/* .my-input111{
  height: 20 !important;
} */

.TextField-without-border-radius fieldset {
  border-radius: 0;
  /* height: 30px;/ */
}

.MuiTextField-root input {
  /* 14.5px = 18.5px - 4px (note: 18.5px is the input's default padding top and bottom) */
  padding-top: 8.5px;
  padding-bottom: 8.5px;
}

.MuiTextField-root label {
  top: -3px;
}

.MuiTextField-root label[data-shrink='true'] {
  top: 0;
}

.inline-more-details {
  /* background-color: #fff !important; */
  padding: 10px !important;
}

.inline-more-details strong {
  display: block;
  margin-bottom: 10px;
}

.inline-more-details .inline-more-detail-box {
  margin-bottom: 10px;
}

.inline-more-details .inline-more-detail-box:last-child {
  margin-bottom: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.border {
  border: 1px solid #eee;
}

.close-btn-float {
  position: absolute;
  right: -5px;
  top: -5px;
}

.incoming-ref-con {
  .img-con {
    height: 200px;
    position: relative;
    cursor: pointer;
    background-color: 1px solid #eee;
    border-radius: .5rem;

    img {

      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    .download-btn {
      border: 1px solid #eee;
    }

    .overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
      opacity: 0;
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }


  .list-con {
    margin-bottom: 15px;

    h4 {
      margin: 0;
    }

    p {
      text-align: right;
      margin: 0;
    }
  }

  .btn-link {
    a {
      text-decoration: none !important;
    }
  }

  .comment-con {
    margin-top: 15px;

    h4 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.the-table.MuiTableContainer-root {
  max-height: 500px;
  max-width: 150%;

}

.MuiTable-root {
  font-size: 14px;
  width: 100%;

  .MuiTableHead-root {

    .MuiTableRow-root,
    .MuiTableCell-stickyHeader {
      background-color: #39694a;
      font-size: 12px !important;

      .MuiTableCell-head {
        color: #ffffff !important;

      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      font-size: 40 px !important;

      &:nth-of-type(odd) {
        background-color: #f2f2f2,
      }
    }
  }
}
