.bordered-table {
    border: 1px solid var(--lighter-text);
    border-radius: 4px;
    font-size: 12px;
    letter-spacing: 0.6px;
}

.bordered-table>thead>tr,
.dialog-table>thead>tr {
    /* background: linear-gradient(to top,
            rgba(108, 156, 122, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9),
            rgba(36, 89, 54, 0.9)); */
            background: var(--main-green);
            border: 1px solid var(--main-green);

}

.bordered-table>thead>tr>th,
.dialog-table>thead>tr>th {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
}

.bordered-table>tbody>tr>td,
.bordered-table>tbody>tr>td>span {
    font-family: 'open-sans', sans-serif, Tahoma;
    font-size: 13px;
    font-weight: 500;
    color: var(--normal-text);
}

.bordered-table>tbody>tr>td>span {
    color: #fff;
}

.bordered-table>tbody>tr:nth-child(even),
.dialog-table>tbody>tr:nth-child(even) {
    background: #f9f9f9;
}

.bordered-table>tbody>tr:hover:nth-child(even),
.dialog-table>tbody>tr:hover:nth-child(even) {
    /* background: #ceccccaa; */
    background: var(--transparent-green);
}

.dialog-table>tbody>tr>td:first-child {
    color: var(--faded-main-green);
    font-weight: 600;
}

.bordered-table>tbody>tr>td>button,
tbody>tr>td>button {
    padding: 2px 6px;
    font-size: 0.9em;
}

.list-table {
    display: table;
    width: 100%;
    border: 1px solid var(--lighter-text);
}

.list-table tbody > tr {
    border: 1px solid var(--lighter-text);
    background: #efefef;
}

.list-table tbody > tr > td {
    padding: 7px;
    font-size: 13px;
    line-height: 20px;
    color: var(--normal-text);
    font-weight: bold;
}

.list-table tbody > tr > td:first-child {
    font-weight: bold;
}

.list-table tbody > tr > td > i {
    font-size: 18px;
    margin: 3px 5px 0;
    line-height: 20px;
}

.list-table tbody > tr > td > i.success {
    color: limegreen;
}

.list-table tbody > tr > td > i.danger {
    color: #FF0000;
}

.list-table tbody > tr > td > i.secondary {
    color: #AFAFAF;
}

/* Dialog table header with icon */
.dialog-table-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 20px;
    padding: 10px 10px;;
    width: 100px;
    flex-direction: row;
    white-space: nowrap;
    border-left: 4px solid var(--golden-rod);
}

.dialog-table-header > i {
    display: block;
    position: relative;
    font-size: 32px;
    color: var(--golden-rod);
    font-weight: 400;
    margin-right: 15px;
}

.dialog-table-header > span {
    display: block;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #666;
}


/* References Summary Table */
.outgoing-ref-table {
    display: table;
    width: 600px;
    margin: 30px auto 20px;
}

.outgoing-ref-table caption {
    background: var(--faded-main-green);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #fff;
}

.outgoing-ref-table tr:hover {
    color: #000;
    background: rgba(36, 89, 54, 0.295)
}

.outgoing-ref-table .rowBold {
    font-weight: bold;
    color: var(--faded-main-green);
}


/* Pagination */
.pagination {
    display: flex;
    background: #fff;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.pagination button {
    display: block;
    padding: 7px 14px;
    text-decoration: none;
    transition: background-color 0.3s;
    color: var(--normal-text);
    border: none;
    font-weight: 600;
    border-top: 1px solid var(--lighter-text);
    border-bottom: 1px solid var(--lighter-text);
    cursor: pointer;
    width: 40px;
    text-align: center;
}

.pagination button:first-child {
    border-left: 1px solid var(--lighter-text);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination button:last-child {
    border-right: 1px solid var(--lighter-text);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination button.active {
    background: var(--faded-main-green);
    color: #fff;
    cursor: default;
    border-top: 1px solid var(--main-green);
    border-bottom: 1px solid var(--main-green);
}

.pagination button:hover:not(.pagination button.active) {
    background: var(--golden-rod);
    border-top: 1px solid var(--golden-rod);
    border-bottom: 1px solid var(--golden-rod);
    color: #fff;
}


/* 404 page */
.page-background {
    display: flex;
    position: relative;
    background: #FFF;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';

}

.page-background .error-page {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.page-background .error-page h1 {
    display: flex;
    color: var(--faded-main-green);
    font-size: 160px;
    line-height: 160px;
    text-shadow: 5px 10px 25px rgba(0, 0, 0, 0.5);
    opacity: 0;

}

.page-background .error-page img {
    display: block;
    position: absolute;
    width: 500px;
    transition: 0.5s ease;
    transform: translateY(-100px);
    animation: animateImage 1200ms ease-in-out;
}

@keyframes animateImage {
    from {
        opacity: 0;
        transform: translateY(-180px);
    }

    to {
        opacity: 1;
        transform: translateY(-100px);
    }
}

.page-background .error-page h2 {
    font-size: 42px;
    color: var(--golden-rod);
    z-index: 2;
}

.page-background .error-page h4 {
    font-weight: 300;
    margin: 10px 0 20px;
    display: block;
    font-size: 22px;
    color: var(--normal-text);

}

.page-background .error-page .links {
    display: flex;
    flex-direction: row;
}

.page-background .error-page .links a {
    display: inline-block;
    text-decoration: none;
    padding: 8px 22px;
    background: var(--faded-main-green);
    color: #fff;
    border-radius: 3px;
    font-size: 15px;
}

.page-background .error-page .links a:hover {
    background: var(--golden-rod);
}

.page-background .error-page .links a:hover {
    background: var(--golden-rod);
}

.page-background .error-page .links .spacer {
    display: inline-block;
    width: 15px;

}

@media screen and (max-width:525px) {
    .page-background .error-page img {
        width: 400px;
    }

    .page-background .error-page h2 {
        font-size: 24px;
    }
}

@media screen and (max-width:350px) {
    .page-background .error-page img {
        width: 300px;
        transform: translateY(-50px);
    }

    .page-background .error-page h2 {
        font-size: 18px;
    }

    .page-background .error-page h4 {
        font-weight: 300;
        font-size: 10px;
        margin: 0 20px 20px;
        text-align: center;

    }
}

/**
    Send Billing Reports attached files summmary
*/
label.send-mail {
    display: inline-block;
    position: relative;
    color: #085680;
    text-decoration: underline;
    padding: 7px 15px;
    background: var(--lighter-text);
    border-radius: 15px;
    margin: 0 5px;
    cursor: pointer;
}

label.send-mail:hover {
    background: var(--transparent-green);
}


/**
Special filter and search group
*/
.search-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
}

.search-buttons .filter-by {
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.search-buttons .select,
.search-buttons .search-filter {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(gainsboro, whitesmoke);
    cursor: pointer;
    text-decoration: none;
    color: #000;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.search-buttons .select:hover,
.search-buttons .search-filter:hover {
    background: linear-gradient(silver, gainsboro);
}

.search-buttons .search-filter {
    /* background: linear-gradient(var(--main-green), var(--faded-main-green));
    color: #fff; */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}

.search-buttons input[type=text] {
    position: relative;
    display: inline-block;
    padding: 5px 8px;
    color: var(--normal-text);
    outline: none;
    border: 1px solid var(--lighter-text);
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.6px;
    min-width: 250px;
    margin-left: -1px;

}

.search-buttons input[type=text]:focus {
    border: 1px solid var(--bluish);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

.search-buttons .search-group.active {
    transform: translateX(-300px);
}
