/*=======================================
LOGIN
=======================================*/
.icofont-mr {
    margin-right: 5px;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(-45deg, var(--golden-rod), #666, var(--main-green), var(--lighter-main-green), var(--faded-main-green), var(--main-green));
    background: linear-gradient(-45deg, white, gainsboro)
}

.login-container::before {
    position: absolute;
    content: '';
    width: 300px;
    height: 300px;
    border-radius: 100%;
    background: var(--faint-golden-rod);
    bottom: 50px;
    left: 20%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1),
        0 0 30px rgba(0, 0, 0, 0.1),
        0 0 60px rgba(0, 0, 0, 0.1),
        0 0 120px rgba(0, 0, 0, 0.1),
        0 0 240px rgba(70, 0, 0, 0.1);


}

.login-container::after {
    position: absolute;
    content: '';
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background: radial-gradient(var(--main-green), var(--faded-main-green));
    /* rgba(70,160,140,0.5); */
    top: 20%;
    left: 28%;
    box-shadow: 0 0 15px var(--lighter-text),
        0 0 30px var(--lighter-text),
        0 0 60px var(--lighter-text),
        0 0 120px var(--lighter-text),
        0 0 240px var(--lighter-text);

}

.login-container h1 {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    color: var(--lighter-text);
    font-family: Poppins, sans-serif;
    letter-spacing: 6px;
    white-space: nowrap;
    text-transform: uppercase;
    text-shadow: 3px 6px 20px rgba(0, 0, 0, 0.5);
    animation: rotateText 5s linear infinite;
}

@keyframes rotateText {
    from {
        transform: translate(-50%, -50%) rotateY(0deg)
    }

    to {
        transform: translate(-50%, -50%) rotateY(-359deg);
    }
}

@media screen and (max-width: 1024px) {
    .login-container h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 947px) {
    .login-container h1 {
        font-size: 70px;
    }
}

@media screen and (max-width: 767px) {
    .login-container h1 {
        display: none;
    }
}

.login-container .login {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 700px;
    min-height: 400px;
    border-radius: 10px;
    background: transparent;
    box-shadow: 5px 15px 35px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(15px);
    overflow: hidden;
    z-index: 1;
}

@media screen and (max-width: 640px) {
    .login-container .login {
        grid-template-columns: 1fr;
        width: 90%;
    }
}

@media screen and (max-width: 640px) {
    .login-container .login {
        width: 80%;
        border-radius: 5px;
    }
}

.login-container .login::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(70, 120, 60, 0.4);
    pointer-events: none;
}

.login-container .login .header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.login-container .login .header>img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50%;
    width: 250px;
    height: 250px;
    animation: animate-image 1s ease;
}

@keyframes animate-image {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fade-left {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@media screen and (max-width: 640px) {
    .login-container .login .header {
        flex-direction: row;
        padding-bottom: 0;
        padding-top: 0;
        margin-left: 0;
    }

    .login-container .login .header>img {
        width: 150px;
    }
}


.login-container .login .header .header-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.8s ease;
    transform: translateY(-20px) rotateY(-360deg) rotateX(-360deg);
    transition-delay: 1000ms;
    /* animation: animateHeaderDetails 0.7s linear;
    animation-delay: 1s;   */
}

@keyframes animateHeaderDetails {
    0% {
        transform: translateY(0) rotateY(0) rotateX(0);

    }

    70% {
        transform: translateY(-5) rotateY(-360deg) rotateX(-360deg);

    }

    100% {
        transform: translateY(-20px) rotateY(-360deg) rotateX(-360deg);
    }
}

@media screen and (max-width: 500px) {
    .login-container .login .header>img {
        display: none;
    }

    .login-container .login .header .header-details {
        transform: rotateY(-360deg) rotateX(-360deg);
        margin: 30px 0;
    }

}

.login-container .login .header .header-details>img {
    position: relative;
    display: block;
    height: 80px;
    width: 100px;
}

.login-container .login .header .header-details p {
    position: relative;
    display: block;
    font-weight: 500;
}

.login-container .login .header .header-details p>span {
    position: relative;
    display: block;
    font-weight: 700;
    font-size: 1.4em;
}

.login-container .login-data {
    position: relative;
    display: flex;
    flex-direction: column;
    background: gainsboro;
    padding: 20px 20px 20px;
    height: 100%;

}

.login-container .login-data>h3 {
    position: relative;
    display: inline-block;
    padding: 5px 12px;
    color: gainsboro;
    background: var(--main-green);
    margin: 20px 20px 20px -20px;
    font-weight: 400;
    font-size: 1.6em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateX(-20px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.error-message {
    display: none;
    border: 2px solid var(--reddish);
    border-radius: 5px;
    background: rgb(221, 156, 156);
    padding: 15px;
    font-size: 1em;
    transform: translateY(-30px);
    color: var(--normal-text);

}

@media screen and (max-width: 640px) {
    .login-container .login-data {
        padding-top: 0;
    }

    .login-container .login-data>h3 {
        transform: translateX(0);
        margin-top: 0;
    }

    .login-details {
        transform: translateY(-20px);
    }
}

/*
.login-container .login-data > h3::before {
    position: absolute;
    content: '';
    width: 20px;
    height: 100%;
    left: 0;
    top: 5px;
    background:var(--main-green);
    transform: skewY(20deg);
    z-index: 0;
} */

.login-details {
    display: block;
    margin-top: 20px;
}

.login-container .login-data .login-details h3 {
    position: relative;
    transform: translateY(-20px);
    font-weight: 600;
    color: #000;
    font-size: 1.5em;
}

.login-container .login-data .login-details h4 {
    position: relative;
    transform: translateY(-10px);
    font-weight: 500;
    color: var(--normal-text);
}

@media screen and (max-width: 640px) {
    .login-container .login-data {
        height: 300px;
    }

    .login-container .login-data .login-details h3,
    .login-container .login-data .login-details h4 {
        transform: translateY(0);
        margin-bottom: 15px;

    }
}

.login-container .login-data .login-details input[type=email],
.login-container .login-data .login-details input[type=text],
.login-container .login-data .login-details input[type=password] {
    position: relative;
    border: 1px solid var(--lighter-text);
    outline: none;
    padding: 10px;
    padding-left: 35px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.02);
    display: block;
    min-width: 300px;
}

.login-container .login-data .login-details .controls {
    margin-bottom: 20px;
}

.login-container .login-data .login-details .controls>label {
    color: red !important;
}

.login-container .login-data .login-details .controls>span {
    position: absolute;
    z-index: 1;
    left: 30px;
    transform: translateY(12px);
    font-size: 18px;
    color: var(--lighter-text);

}

@media screen and (max-width: 575px) {

    .login-container .login-data .login-details .controls>span {
        transform: translateY(12px) translateX(-20px);

    }
}

.login-container .login-data .login-details input[type=email]:focus,
.login-container .login-data .login-details input[type=text]:focus,
.login-container .login-data .login-details input[type=password]:focus {

    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

}

#txtValidator {
    font-weight: bold;
    letter-spacing: 3px;
}

@media screen and (max-width: 640px) {

    .login-container .login-data .login-details input[type=email],
    .login-container .login-data .login-details input[type=text],
    .login-container .login-data .login-details input[type=password] {
        min-width: 400px;
        margin-bottom: 10px;
    }

    .login-container .login-data .login-details>a {
        margin-bottom: 30px;

    }

    .login-container .login-data .login-details button {
        margin-right: 15px;
    }
}

@media screen and (max-width: 500px) {

    .login-container .login-data .login-details input[type=email],
    .login-container .login-data .login-details input[type=text],
    .login-container .login-data .login-details input[type=password] {
        min-width: 100%;
    }

}

.login-container .login-data .login-details .form-inline {
    display: inline-block !important;
}

.login-container .login-data .login-details button {
    display: inline-block;
    background: var(--main-green);
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px 20px;
    border: 1px solid var(--main-green);
    color: #fff;
    width: 140px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 15px;

}

.login-container .login-data .login-details .form-inline button:last-child {
    color: var(--main-green);
    font-weight: bold;
    background: #fff;
    border: 1px solid var(--main-green);
}

.login-container .login-data .login-details button:hover {
    background: var(--faded-main-green);
}

.login-container .login-data .login-details .form-inline button:last-child:hover {
    background: var(--main-green);
    font-weight: normal;
    color: #fff;
}

.login-container .login-data .login-details>a {
    display: inline-block;
    text-decoration: none;
    color: var(--faded-text);

}

.login-container .login-data .login-details a:hover,
.login-container .login-data .cant-access a:hover {
    text-decoration: underline;
}

.login-container .login-data .cant-access {
    position: absolute;
    bottom: 0;
    padding: 10px 20px;
    display: block;
    width: 100%;
    background: silver;
    border-top: 1px solid #aaa;
    transform: translateX(-20px);
}

.login-container .login-data .cant-access>a {
    display: block;
    line-height: 20px;
    color: var(--main-green);
    text-decoration: none;

}


/*=======================================
    BUTTONS
=======================================*/
button:focus {
    box-shadow: 0 0 0 var(--transparent-green);
}

.btn-grey {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(gainsboro, whitesmoke);
    cursor: pointer;
    text-decoration: none;
    color: #000;
    border-radius: 3px;
}

.btn-grey:hover {
    background: linear-gradient(silver, gainsboro);
}

.btn-green {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 6px 15px;
    background: linear-gradient(var(--main-green), var(--faded-main-green));
    background: var(--main-green);
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
}

.btn-green:hover {
    background: linear-gradient(silver, gainsboro);
    color: var(--normal-text);
}

.btn-danger {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(#920404, var(--danger));
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.btn-danger:hover {
    background: linear-gradient(silver, gainsboro);
    color: var(--normal-text);
}

.btn-info {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(#007bff, #0d5097);
    color: #fff;
    cursor: pointer;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.btn-info:hover {
    background: linear-gradient(silver, gainsboro);
    color: var(--normal-text);
}

.btn-warning {
    position: relative;
    display: inline-block;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(#ffb000, #c5c203);
    color: var(--normal-text);
    cursor: pointer;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.btn-warning:hover {
    background: linear-gradient(silver, gainsboro);
    color: var(--normal-text);
}

.badge-item-success {
    display: inline-block;
    position: relative;
    color: #fff !important;
    padding: 3px 5px;
    font-weight: 700;
    border-radius: 4px;
    background: var(--lime-green);
}

.badge-success {
    display: block;
    position: relative;
    color: #fff;
    font-weight: 500;
    background: var(--lime-green);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-approved {
    display: block;
    position: relative;
    color: #fff;
    padding: 1px 5px;
    font-weight: 500;
    background: var(--success);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-danger {
    display: block;
    position: relative;
    color: #fff;
    padding: 2px 3px;
    font-weight: 500;
    background: var(--danger);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-warning {
    display: block;
    position: relative;
    color: #fff;
    padding: 2px 3px;
    font-weight: 500;
    background: var(--warning);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-warning-2 {
    display: block;
    position: relative;
    color: #fff;
    padding: 2px 3px;
    font-weight: 500;
    background: var(--lighter-gold);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-info {
    display: block;
    position: relative;
    color: #fff;
    padding: 2px 3px;
    font-weight: 500;
    background: var(--bluish);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-cancel {
    display: block;
    position: relative;
    color: #fff;
    padding: 2px 3px;
    font-weight: 500;
    background: var(--light-red);
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

.badge-disapproved {
    display: block;
    position: relative;
    color: #FFF;
    padding: 2px 3px;
    font-weight: 500;
    background: #ED6F66;
    border-radius: 15px;
    text-align: center;
    padding: 5px 0;
}

/*=======================================
    FOOTER
=======================================*/
.footer {
    display: block;
    background: silver;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.4);
}

.footer .footer-text {
    display: block;
    text-align: center;
    color: var(--main-green);
    font-weight: 600;
}


/**************************************************
    TOOL-TIP SETTINGS
/**************************************************/
.tool-tip-body {
    position: relative;
}

.tool-tip-body .tool-tip {
    font-size: 1em;
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    color: var(--medium-text);
    text-align: left;
    border-radius: 6px;
    border: 2px solid #ccc;
    padding: 7px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -120px;
    opacity: 0;
    transition: opacity 0.6s ease;
    transition-delay: 1s;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.3);
    letter-spacing: .7px;
}

.tool-tip-body .tool-tip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    /*NB: margin-left centralizes the arrow. They are actually borders like triangles the size of the border width determines the height of the arrow...*/
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    display: block;
}

.tool-tip-body:hover .tool-tip,
.tool-tip-body.active .tool-tip {
    visibility: visible;
    opacity: 1;
}

.tool-tip>div {
    display: block;
    position: relative;
    padding: 10px 5px;
    font-weight: 400;
    cursor: pointer;
}

.tool-tip>div:first-child {
    font-weight: 600;
    cursor: default;
    color: var(--faded-main-green);
}

.tool-tip>div:not(:first-child):hover,
.tool-tip>div>a:hover {
    background: var(--golden-rod);
    color: #fff;
}

.tool-tip>div>a {
    color: var(--medium-text);
    text-decoration: none;
}


/*==============================================
    SPECIAL INPUT GROUPS - FORM CONTROLS
===============================================*/
/*Form Group settings */
.form-row {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
}

.form-row-1-3 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 3fr;
}

.form-row-3x {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
}

.form-row-3 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
}

.form-row-5 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
}

.form-row-4 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
}

.form-row-2-1 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr 1fr;
}

.form-row-1-2 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 2fr;
}

.form-row-1-1-2 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr) 2fr;
}

.form-row-2-1-1 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 2fr repeat(2, 1fr);
}

.form-row-1-2-1 {
    position: relative;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 2fr 1fr;
}

@media screen and (max-width: 640px) {

    .form-row,
    .form-row-1-3,
    .form-row-1-2-1,
    .form-row-2-1,
    .form-row-1-2,
    .form-row-3,
    .form-row-4,
    .form-row-5,
    .form-row-1-1-2,
    .form-row-2-1-1 {
        grid-template-columns: 1fr;
        gap: 0;
    }
}

hr.golden-rule {
    display: block;
    position: relative;
    width: 100%;
    border: 1px solid var(--faint-golden-rod);
    margin-bottom: 20px;
}

.row-title {
    position: absolute;
    display: inline-block;
    padding: 5px 12px;
    color: gainsboro !important;
    background: var(--lighter-main-green);
    margin: 20px 20px 20px -20px;
    font-weight: 400 !important;
    font-size: 1em !important;
    right: 20px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: translateX(42px) translateY(-40px);
    box-shadow: -5px 5px 12px rgb(0 0 0 / 60%);
    z-index: 1;
    text-transform: uppercase;
}

.d-block {
    display: block !important;
}

.mt-2 {
    margin-top: 20px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 20px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.mb-3 {
    margin-bottom: 30px;
}

.py-1 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.py-2 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.bg-whitesmoke {
    background: whitesmoke !important;
}

.bg-silver {
    background: rgb(243, 218, 218) !important;
}

.bg-white {
    background: #fff !important;
}

.d-none {
    display: none !important;
}

.error-border {
    border: 1px solid red !important;
}

.input-group,
.input-group-inline {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-group label {
    position: relative;
    display: block;
    margin-bottom: 2px;
    font-size: 1.1em;
    color: var(--medium-text);
    font-weight: 600;
}

.input-group label>span {
    position: relative;
    color: red;
    font-weight: bold;
    font-size: 13px;
    margin-right: 3px;
}

.input-group>label>span~i {
    display: inline-block;
    position: relative;
    color: var(--main-green);
    font-size: 18px;
    transition: 0.3s ease;
    animation: animate-spinner 1.5s linear infinite;
}

@keyframes animate-spinner {
    0% {
        transform: rotate(1deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.input-group>.control-info {
    position: relative;
    display: block;
    font-weight: normal;
    color: var(--bluish);
    transform: translateY(-5px);
    font-size: 11px;
}

.input-group>.control-info>i {
    font-size: 13px;
}

.input-group input.form-control,
.input-group textarea.form-control,
.input-group select {
    position: relative;
    display: block;
    padding: 5px 8px;
    color: var(--normal-text);
    outline: none;
    border: 1px solid var(--lighter-text);
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.6px;
    border-radius: 4px;
}

.input-group-inline input.form-control {
    position: relative;
    display: inline-block;
    padding: 5px 8px;
    color: var(--normal-text);
    outline: none;
    border: 1px solid var(--lighter-text);
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.6px;
    background: #eee;
    width: 100%;
    border-radius: 4px;
}

.input-group-inline input.form-control~button {
    /* Button immediately beside it */
    width: 50px;
    height: 32px;
    background: #eee;
    position: absolute;
    right: -5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.input-group-inline input.form-control~button>i {
    font-size: 18px;
    color: #555;
}

.input-group.special-select {
    display: inline !important;
    width: 300px !important;
}

.inputgroup.special-select select {
    padding: 10px !important;
}

.input-group select {
    padding: 4px 8px !important;
}

.input-group input.form-control:disabled,
.input-group textarea.form-control:disabled,
.input-group select:disabled {
    background: #ddd;
    border: 1px solid var(--lighter-text);
    font-weight: bold;
}

.input-group input.form-control:focus,
.input-group textarea.form-control:focus,
.input-group select:focus {
    border: 1px solid var(--bluish);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

.inline-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inline-input-group input[type=checkbox] {
    display: inline-block;
    position: relative;
    height: 1.1em;
    margin-right: 5px;
    outline: none;
    appearance: none;
}

.inline-input-group input[type=checkbox]::before {
    position: absolute;
    content: '';
    background: var(--bluish);
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid whitesmoke;
    transform: translateX(-17px);
}

.inline-input-group input[type=checkbox]:checked::before {
    position: absolute;
    font-family: "Icofont";
    content: '\ec4b';
    background: var(--bluish);
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border: 1px solid whitesmoke;
    transform: translateX(-17px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
}

.inline-input-group label {
    font-weight: 600;
    display: inline;
    line-height: 1.1em;
    transform: translateY(-5px);
    font-size: 13px;
    color: var(--normal-text);
    margin-left: 17px;
}

.input-form-inline {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-left: 0;
}

.input-form-inline>label {
    font-weight: normal;
    position: relative;
    color: var(--normal-text);
    margin-right: 5px;
}

.input-form-inline>input[type=text],
.input-form-inline>select {
    position: relative;
    padding: 4px 10px;
    border: 1px solid var(--lighter-text);
    border-radius: 3px;
    width: 70px;
    font-size: 14px;
    outline: none;
}

.input-form-inline>button {
    margin-left: -3px
}

/*==============================================
    SPECIAL RADIO BUTTON
===============================================*/
.filter,
.search-group {
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    width: 200px;
    background: #fff;
    background: linear-gradient(#fff, whitesmoke);
    border-radius: 10px;
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.5);
    text-align: left;
    padding: 15px;
    z-index: 102;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;

}

.filter.active,
.search-group.active {
    visibility: visible;
    opacity: 1;
}

.filter::before,
.search-group::before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    right: 20px;
    transform: rotate(45deg);
    top: -5px;
}

.filter .filter-list,
.search-group .search-list,
.input-group .search-list {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid var(--lighter-text);
    font-weight: 600;
    color: #222;
    transition: 0.4s;
}

.filter .filter-list:last-child,
.search-group .search-list:last-child,
.input-group .search-list:last-child {
    border-bottom: none;
}

.filter .filter-list:hover,
.search-group .search-list:hover,
.input-group .search-list:hover {
    background: whitesmoke;
}

.filter .filter-list>input[type=radio],
.search-group .search-list>input[type=radio],
.input-group .search-list>input[type=radio] {
    outline: none;
    appearance: none;
}

.filter .filter-list>span,
.search-group .search-list>span,
.input-group .search-list>span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--bluish);
}

.input-group .search-list>span {
    /* width: 20px;
    height: 20px; */
    border-radius: 0;
}

.filter .filter-list>span::before,
.search-group .search-list>span::before,
.input-group .search-list>span::before {
    position: absolute;
    content: '';
    font-family: 'Icofont';
    color: #fff;
    font-size: 7px;
    font-weight: normal;

}

.filter .filter-list>input[type=radio]:checked~span::before,
.search-group .search-list>input[type=radio]:checked~span::before,
.input-group .search-list>input[type=radio]:checked~span::before {
    content: '\ec4b';
}

/*==============================================
    USER SEARCH
===============================================*/
.search {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 500px;

}

.search>input[type=text] {
    outline: none;
    appearance: none;
    display: block;
    position: relative;
    padding: 8px;
    padding-left: 140px;
    padding-right: 50px;
    border-radius: 5px;
    border: 1px solid var(--lighter-text);
    width: 100%;
    font-size: 14px;
    font-weight: normal;
}

.search>input[type=text]:focus {
    box-shadow: 0 0 0 4px var(--transparent-green);
}


.search>#btnSearchBy {
    position: absolute;
    display: block;
    background: transparent;
    outline: none;
    border: none;
    left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 10px;
    cursor: pointer;
    color: #222;
    font-weight: normal;
}

.search>#btnSearchBy:hover {
    color: #666;
}

.search>#btnSearchBy>i {
    font-size: 10px;
    padding: 3px;
    transition: 0.5s ease;
}


.search>#btnSearchUser {
    position: absolute;
    display: block;
    right: 6px;
    border: none;
    outline: none;
    color: #666;
    background: transparent;
    cursor: pointer;
    padding: 5px 8px;
    height: 33px;

}

.search>#btnSearchUser>i {
    font-weight: bold;
}


/*==============================================
    EMAIL BOX
===============================================*/
.emails-box {
    width: 100%;
    display: block;
    position: relative;
    background: gainsboro;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 5%);
    padding: 15px;
    font-size: 16px;
    text-align: center;
    color: var(--lighter-text);
}

.emails-box>span {
    padding: 7px 13px;
    display: inline-block;
    position: relative;
    background: rgb(196, 195, 195);
    color: black;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 13px;
    transition: 0.3s ease;

}

.emails-box>span:hover {
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 25%);
}

/*==============================================
    TOOL TIP
===============================================*/
.t-tip::before {
    position: absolute;
    display: block;
    text-align: center;
    content: attr(data-title);
    top: 40px;
    right: 0;
    min-width: 200px;
    max-width: 300px;
    padding: 10px;
    font-family: 'Poppins-Light';
    font-size: 1em;
    font-weight: normal;
    background: #2e2e2e;
    color: #fff;
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    transition-delay: 0.5s;
    z-index: 102;
}

.t-tip::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    bottom: -15px;
    right: 20px;
    font-family: 'Poppins-Light';
    background: #2e2e2e;
    transform: rotate(45deg);
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
    transition-delay: 0.5s;
    z-index: 101;
}

.t-tip:hover::before,
.t-tip:hover::after {
    visibility: visible;
    opacity: 1;
}


/*==============================================
    CHECK BOX GROUP
===============================================*/
.checkbox-group {
    display: block;
    position: relative;
    background: #fff;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);
    width: 300px;
}

.checkbox-group .checkbox-row {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--lighter-text);
    border-bottom: 1px solid var(--lighter-text);
}

.no-border {
    border: none !important;
}

.bg-success {
    background: rgb(188, 231, 188);
}

.bg-danger {
    background: rgb(231, 193, 188);
}

.checkbox-group .checkbox-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border: 1px solid var(--lighter-text);
    border-bottom: none;
}

.checkbox-group .checkbox-container:hover {
    background: whitesmoke;
}

.checkbox-group .checkbox-container input[type=checkbox] {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    outline: none;
    border: none;
    appearance: none;
    cursor: pointer;
    transform: translateY(-8px);
}

.checkbox-group .checkbox-container label {
    position: relative;
    margin-top: 5px;
    cursor: pointer;
}

.checkbox-group .checkbox-container input[type=checkbox]::before {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '';
    background: var(--lighter-text);
    color: #fff;
    font-size: 8px;
    width: 14px;
    height: 14px;
    top: -5px;
    left: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2),
        0 -1 2px rgba(255, 255, 255, 0.2);
    border: 1px solid var(--lighter-text);
}

.checkbox-group .checkbox-container input[type=checkbox]:checked::before {
    background: var(--bluish);
    content: '\ec4b';
    font-family: 'Icofont';
}

.special-button {
    margin-top: 22px;
    width: 50%;
}


/**
    User Role Groups
*/
.role-group {
    display: block;
    position: relative;
    background: #EBEBEB;
    margin-bottom: 10px;
}

.role-group .role-group-title {
    background: linear-gradient(-90deg, transparent, var(--faded-main-green));
    color: #FFF !important;
    font-weight: normal !important;
    padding: 5px 10px;
    font-size: 14px !important;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.role-group .role-group-inputs {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 8px 15px 12px 15px;
}

.role-group .role-group-inputs>label {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
    cursor: pointer;
}

.role-group .role-group-inputs input[type=radio] {
    display: inline-block;
    position: relative;
    outline: none;
    appearance: none;
    margin-left: 25px;
    margin-right: 5px;
    cursor: pointer;

}

.role-group .role-group-inputs input[type=radio]::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    background: var(--bluish);
    width: 18px;
    height: 18px;
    border: 4px solid var(--transparent-main-green);
    transform: translate(-20px, -10px);
    color: #FFf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2),
        0 -1 2px rgba(255, 255, 255, 0.2);
    border: 1px solid var(--lighter-text);

}

.role-group .role-group-inputs input[type=radio]:checked::before {
    background: var(--bluish);
    content: '\ec4b';
    font-family: 'Icofont';
}

.role-group .role-group-inputs button {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 0px;
}

/* Special file upload */
.upload-file-area {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width: 350px; */
    background: #EFEFEF;
    border: 4px dashed darkgrey;
    border-radius: 4px;
    padding: 25px;
}

.upload-file-area .btn-upload {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 35px;
    background: linear-gradient(gainsboro, whitesmoke);
    text-decoration: none;
    color: #000;
}

.upload-file-area .btn-upload:hover {
    background: linear-gradient(silver, gainsboro);
}

.upload-file-area .btn-upload>input[type=file] {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-file-area .control-info {
    font-size: 12px;
    margin-top: 5px;
    color: var(--bluish);
}

.upload-file-area>label {
    display: block;
    margin-top: 10px;
    font-weight: normal;
}

.upload-file-area>label>span {
    color: var(--main-green);
}

.upload-file-area>span {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--medium-text)
}

.upload-file-area>span:last-child {
    margin-bottom: 0;
    color: var(--main-green)
}

.center-component {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

/* Smaller Upload file area */

/*==============================================
    SPECIAL FILE UPLOAD
===============================================*/

.upload-file-area-small {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* width: 350px; */
    background: #EFEFEF55;
    border: 2px dotted silver;
    border-radius: 4px;
    padding: 10px 15px;
    font-family: Poppins;
    margin-bottom: 6px;
}

.upload-file-area-small>div {
    display: flex;
}

.upload-file-area-small .btn-upload {
    display: inline-block;
    outline: none;
    border: none;
    background: none;
    color: var(--bluish);
    font-size: 11px;
    padding: 0;
    margin-left: 3px;
    transition: 0.3s;
    cursor: pointer;
}

.upload-file-area-small .btn-upload:hover {
    text-decoration: underline;
    background: none;
}

.upload-file-area-small .btn-upload:focus {
    box-shadow: none;
}

.upload-file-area-small i {
    font-size: 16px;
    color: dimgrey;
    margin-right: 5px;
}

.upload-file-area-small .btn-upload>input[type=file] {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-file-area-small .control-info {
    font-size: 12px;
    margin-top: 5px;
    color: var(--bluish);
}

.upload-file-area-small label {
    display: block;
    font-weight: normal;
    font-size: 10px;
}

.upload-file-area-small>label>span {
    color: var(--main-green);
    font-size: 10px;
}

.upload-file-area-small>span {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--medium-text)
}

.upload-file-area-small>span:last-child {
    margin-bottom: 0;
    color: var(--main-green)
}
