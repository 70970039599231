.custom-select-group {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.custom-select-group > #inputValue {
    display: block;
    position: relative;
    padding: 7px 12px;;
    padding-right: 35px;
    color: var(--normal-text);
    outline: none;
    border: 1px solid var(--lighter-text);
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    letter-spacing: 0.6px;
    width: 100%;

}

.custom-select-group > span {
    display: block;
    position: absolute;
    right: 10px;
    top: 9px;
    font-size: 16px;
    font-weight: bold;
}

.custom-select-group > .list-group {
    display: block;
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    top: calc(100% - 10px);
    left: 0;
    border: 1px solid #ccc;
    background: white;
    z-index: 2;
    color: #444;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
    font-size: 14px;
}

.custom-select-group > .list-group > div {
    display: block;
    padding: 7px 15px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    cursor: default;
}

.custom-select-group > .list-group > div:hover {
    background-color: #f1f1f1;
}