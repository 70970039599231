.range-select {
    display: flex;
    position: relative;
    width: 250px;
    background: white;
}

.range-select .range-input {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 3px;
    transition: 0.3s ease;
    padding: 5px 7px;
    padding-right: 2px;
}

.range-input .date-buttons {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 70px;
    padding-right: 5px;
}

.range-input .date-buttons button {
    display: block;
    position: relative;
    outline: none;
    border: none;
    background: transparent;
    padding: 4px 3px;
    cursor: pointer;
    transition: 0.3s ease;
    color: var(--normal-text);
    font-size: 1em;
}

.range-input .date-buttons button:hover {
    color: var(--main-green);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.range-input .date-buttons button.danger:hover {
    color: var(--danger);
}

.range-input .date-buttons button.info:hover {
    color: var(--bluish);
}

.date-items {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 0.9em;
}

.date-items > i {
    display: block;
    position: relative;
    background: linear-gradient(90deg, whitesmoke, gainsboro);
    padding: 1px 5px;
    border: 1px solid #CCC;
    border-radius: 2px;
    margin: 0 3px;
    font-style: normal;
    font-weight: 600;
}

/*
.range-input .date-buttons button:last-child:hover {
    color: var(--danger);
} */