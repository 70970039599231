@font-face {
    font-family: 'Montserrat';
    src: url(./../font/poppins.ttf);
}

#calendar {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 2px;
}

.month {
	padding: 20px;
	background: linear-gradient(135deg, white, white, white, whitesmoke, gainsboro, var(--main-green));
	position: relative;
	overflow: hidden;
	height: 260px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.day {
    padding: 10px;
    height: 70px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: white;
    margin: 5px;
    box-shadow: 2px 3px 5px rgba(0,0,0,0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    font-weight: normal;
}

.day:hover {
	background: rgba(0,50, 0, 0.3);
}

.padding {
    cursor: default !important;
    background-color: whitesmoke !important;
    box-shadow: none !important;
}

#container {
    width: 100%;
}

#weekdays {
    width: 100%;
    display: grid;
    color: var(--main-green);
    grid-template-columns: repeat(7, 1fr);
    font-size: 16px;
}

#weekdays div {
    width: 100px;
    padding: 10px;
}

#header {
    padding: 10px;
    color: var(--main-green);
    font-weight: 700;
    font-size: 26px;
    display: flex;
    justify-content: space-between;
  }

#modalBackDrop {
    position: fixed;
    top: 0;
    left: 0;
    display: flex; /*display flex whenever needed to show*/
    -ms-display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px 0;
    width: 100vw;
    background: rgba(0,0,0,0.5);
    z-index: 499;
    overflow: hidden;
    opacity: 1;
    transition: 1000ms ease;
}

#deleteEventModal, #newEventModal {
    display: block;
    position: relative;
    width: 450px;
    height: 215px;
    background: linear-gradient(135deg,whitesmoke,gainsboro);
    opacity: 1;
    padding: 20px 20px 30px 20px;
    box-shadow: 5px 15px 35px rgba(0,0,0,0.5);

}

.event {
    font-size: 10px;
    padding: 3px 7px;
    background-color: var(--bluish);
    color: white;
    border-radius: 5px;
    max-height: 55px;
    overflow: hidden;
}

.calendar-container {
    display: block;
    width: 100%;
    box-shadow: 3px 15px 35px rgba(0,0,0,0.3);
    padding: 20px;
}

.currentDay {
    background: #a6bae444;
}
